<template>
    <layout-admin-dashboard-view
             :showToolbar="false"
    :backRoute="{name: 'entity-dashboard'}">
        <template v-slot:title>
            <span>{{translateTitleCase(`entity.crud.listTitleGeneric`)}} - {{translateTitleCase(`entity.${entityType}.namePlural`)}}</span>
        </template>

        <template v-slot:default>
            <crud-list

                    :createRoute="{name:'entity-create', params: {entityType}}"
                    :editRoute="getEditRoute"
                    :apiDeleteUrl="'entity/'+entityType"
                    :apiGetUrl="'entity/'+entityType"
                    :apiOrderUrl="'entity/'+entityType+'/setOrdering'"
                    :apiUpdateUrl="getUpdateApiUrl"

                    :showFilters="showingFilters"

            ></crud-list>
        </template>
    </layout-admin-dashboard-view>

</template>

<script>
    import { useStore } from 'vuex'

    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
//    import paginationComposition  from '@/client/extensions/composition/pagination.js';
    import CrudList from '@/client/components/crud/List.vue';
    import { computed, reactive, getCurrentInstance } from 'vue';

    export default {
        props: {
            entityType: {
                type: String,
                default: 'default'
            }
        },
        components: {CrudList},
        setup (props, setupContext ) {
            let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady, asyncStatus,}
        },
        data: function () {
            return {
                showingFilters: false,
            };
        },
        computed : {

        },
        methods: {
            getEditRoute(id) {
                return {name: 'entity-edit', params: {entityType: this.entityType, entityId: id}}
            },
            getUpdateApiUrl(arg) {
                return 'entity/'+this.entityType+'/'+arg;
            },
            getDeleteApiUrl(payload) {
               // arg can be changed, and it will change the payload sent on the delete request
                return 'entity/'+this.entityType;
            },

            getItemRouteConfig (id) {
                return {name: 'entity-edit', params: {entityType: this.entityType, entityId: id}}
            },
        },



    }
</script>

<style scoped lang="scss">
    .meta-column-header{
        white-space: nowrap;
    }

    .list-column-header {
        position: relative;
        cursor: pointer;

        .col-sort-indicator {
            position: absolute;
            top: calc(50% + 1px);
            left: -5px;
            transform: translateY(-50%);
            z-index: 5;
            display: inline-block;
        }
    }
</style>
